import { onMounted, onBeforeUnmount, Ref } from 'vue'

export function useClickOutside(
  el: Ref<HTMLElement | undefined>,
  fn: Function
) {
  function onClick(e: MouseEvent) {
    if (el.value && !el.value.contains(e.target!)) {
      fn()
    }
  }
  onMounted(() => {
    document.addEventListener('click', onClick)
  })
  onBeforeUnmount(() => {
    document.removeEventListener('click', onClick)
  })
}

function useEventListener(
  event: string,
  listener: (this: HTMLElement, ev: Event) => any,
  target: string
): void
function useEventListener<E extends keyof GlobalEventHandlersEventMap>(
  event: E,
  listener: (this: Document, ev: GlobalEventHandlersEventMap[E]) => any,
  target?: Document | string
): void
function useEventListener(
  event: string,
  listener: any,
  target: Document | Window | string = document
) {
  onMounted(() => {
    if (typeof target === 'string') {
      let el = document.querySelector(target)
      el?.addEventListener(event, listener)
    } else {
      target.addEventListener(event, listener)
    }
  })
  onUnmounted(() => {
    if (typeof target === 'string') {
      let el = document.querySelector(target)
      el?.removeEventListener(event, listener)
    } else {
      target.removeEventListener(event, listener)
    }
  })
}

export { useEventListener }
