<template>
  <div class="cantact-us text-xs">
    <div class="top pointer rounded" @click="router.push('/contact')">
      联系我们
    </div>
    <!-- <n-back-top :right="18" /> -->
    <div class="to-top pointer rounded">
      <svg-icon name="totop" />
    </div>
  </div>
</template>

<script setup lang="ts">
const router = useRouter()
</script>

<style lang="scss" scoped>
.cantact-us {
  position: fixed;
  margin-right: 18px;
  right: 0;
  top: 325px;
  width: 36px;
  color: white;
  .top {
    background: var(--primary);
    padding: 10px;
    text-align: center;
  }
  .to-top {
    width: 36px;
    height: 36px;
    margin-top: 12px;
    background: #ffffff;
    box-shadow: 0px 1px 5px 0px rgba(34, 71, 152, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
