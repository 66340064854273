<template>
  <header class="header-container flex items-center">
    <div class="logo">
      <svg-icon name="logo" />
    </div>
    <div id="tabs" class="nav-tabs relative flex-1">
      <n-tabs
        type="line"
        trigger="hover"
        :value="activeTab"
        @update:value="update"
        pane-style="padding: 0"
        :tabs-padding="24"
      >
        <n-tab-pane name="home">
          <template #tab>
            <div @click="router.push('/')" class="my-tab">首页</div>
          </template>
        </n-tab-pane>
        <!-- <n-tab-pane name="product">
          <template #tab>
            <div class="my-tab">产品服务</div>
          </template>
        </n-tab-pane> -->
        <n-tab-pane name="core">
          <template #tab>
            <div class="my-tab">行业解决方案</div>
          </template>
        </n-tab-pane>
        <n-tab-pane name="news">
          <template #tab>
            <div class="my-tab">新闻动态</div>
          </template>
        </n-tab-pane>
        <n-tab-pane name="about">
          <template #tab>
            <div @click="router.push('/about')" class="my-tab">关于司普</div>
          </template>
        </n-tab-pane>
      </n-tabs>
      <ul class="core-product absolute" v-show="activeTab === 'product'">
        <n-grid :cols="2">
          <n-grid-item v-for="product in coreProducts" :key="product.title">
            <li class="core-product-item" @click="toProduct(product.id)">
              <h4 class="text-base">{{ product.title }}</h4>
              <small class="core-product-desc">{{ product.brief }}</small>
            </li>
          </n-grid-item>
        </n-grid>
      </ul>
    </div>
    <div>
      <template v-if="true">
        <n-button
          quaternary
          size="tiny"
          class="btn"
          @click="router.push('/login')"
          >登录</n-button
        >
        <n-button type="primary" size="tiny" class="btn register"
          >注册</n-button
        >
      </template>

      <div v-else>
        <n-popover trigger="click">
          <template #trigger>
            <div class="flex items-center">
              <svg-icon
                name="avatar"
                style="width: 30px; height: 30px"
              ></svg-icon>
              <span style="margin-left: 6px; margin-right: 60px" class="text-xs"
                >用户名</span
              >
            </div>
          </template>
          <div class="edit-user-info text-xs">
            <div
              v-for="info in infos"
              :key="info.label"
              class="flex-between-center"
            >
              <span style="color: var(--text-desc); width: 36px">{{
                info.label
              }}</span>
              <div
                contenteditable
                :data-ph="info.placeholder"
                class="inp flex-1"
              >
                {{ info.value }}
              </div>
              <span style="color: var(--primary)" class="pointer">{{
                info.isEdit ? '保存' : info.editLable
              }}</span>
            </div>
          </div>
        </n-popover>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
const activeTab = ref('')
const update = (val: string) => {
  activeTab.value = val
}
useEventListener(
  'mouseleave',
  (e: MouseEvent) => {
    activeTab.value = ''
  },
  '#tabs'
)

const { coreProducts } = useConfig()
const router = useRouter()

function toProduct(id: number) {
  router.push(`/product/${encodeURIComponent(id)}`)
}

const infos = ref([
  {
    label: '账号名',
    value: '用户名',
    isEdit: false,
    editLable: '修改'
  },
  {
    label: '手机号',
    value: '10086100003',
    isEdit: false,
    editLable: '修改'
  },
  {
    label: '邮 箱',
    value: '',
    isEdit: false,
    editLable: '认证',
    placeholder: '未绑定'
  },
  {
    label: '公 司',
    value: '',
    isEdit: false,
    editLable: '设置',
    placeholder: '未设置'
  }
])
</script>

<style lang="scss" scoped>
.header-container {
  height: 64px;
  background: #e1ebfc;
  box-shadow: 0px 4px 14px 0px rgba(118, 133, 164, 0.43);
  .logo {
    margin-left: 145px;
    vertical-align: middle;
    .icon-logo {
      width: 100px;
      height: 33px;
    }
  }
  .nav-tabs {
    margin-left: 5%;
  }
  .btn {
    width: 60px;
  }
  .register {
    margin-left: 8px;
    margin-right: 30px;
  }
  .core-product {
    background-color: #fff;
    width: 800px;
    line-height: 2;
    margin-top: 0px;
  }
  .core-product-item {
    height: 90px;
    padding: 16px 30px;
    cursor: pointer;
    &:hover {
      background-color: #e6ebf6;
      color: var(--primary);
    }
    .core-product-desc {
      color: var(--text-desc);
    }
    &:hover .core-product-desc {
      color: var(--primary);
    }
  }
}
.my-tab {
  padding: 20px 15px;
}
.edit-user-info {
  width: 222px;
  border-radius: 2px;
  background: #ffffff;
  line-height: 25px;
  .inp {
    margin: 0 16px;
    outline: none;
  }
  .inp:empty::after {
    content: attr(data-ph);
    color: var(--text-desc);
  }
}

:deep(.n-tabs-tab) {
  padding: 0px;
}
:deep(.n-tabs-tab--active) {
  background: #fff;
}
:deep(.n-tabs-nav-scroll-content) {
  border-bottom-color: transparent !important;
}
// :deep(.n-tabs-tab--active) {
//   background-color: #fff;
// }
</style>
